<template>
  <div class="boxFile" @contextmenu.prevent.stop="rightClick">
    <div class="con">

      
      <img v-if="type == 'dir'" @dblclick="openDir" src="@/assets/images/jia.jpg" alt="" srcset="" />
      <img v-if="type == 'zip'" src="@/assets/images/zip.jpg" alt="" srcset="" />
      <img
        v-if="type == 'img'"
        class="img"
        :src="base_url + 'getFile/' + path"
        alt=""
        srcset=""
      />
      <img
        v-if="type == 'other'"
        src="@/assets/images/other.jpg"
        alt=""
        srcset=""
      />
    <!-- <div class="show flex  flex_align_cen">
          <div class="showCon">
              <template v-if="type=='dir'">
                  <div class="text" @click="openDir">打开</div>
                    <div class="text" v-if="enableShow" @click="showImg">预览大屏</div>
              </template>
              <p class="m0 text-center text del" @click="delFile">
                  <i class="el-icon-delete"></i>
              </p>
          </div>
      </div> -->
      </div>
      <el-tooltip class="item" effect="light" :content="name" placement="top" :disabled="name.length<10">
    <p class="text-center m0 " :class="name.length>10?'ellipsis':''" ref="fileName" :contentEditable="contentEditable" @blur="blur">{{name}}</p>
    </el-tooltip>
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="60%">
  <img
        v-if="type == 'img'"
        class="img"
        :src="base_url + 'getFile/' + path"
        alt=""
        srcset=""
      />
</el-dialog>
  </div>
</template>

<script>
import { base_url } from "@/utils/request";
export default {
  name: "File",
  data() {
    return {
      base_url: base_url,
      isRight: false,
      contentEditable:false,
      dialogVisible:false
    };
  },
  props: {
    type: {
      default: "other",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    path: {
      default: "",
      type: String,
    },
    enableShow: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    getOption() {
      let option = [];
      switch (this.type) {
        case "dir":
          option.push({
            callback: this.openDir,
            title: "打开",
          });
          if (this.enableShow) {
            option.push({
              callback: this.showImg,
              title: "预览大屏",
            });
          }
          option.push({
            callback: this.zip,
            title: "压缩为zip",
          });
          break;
        case "zip":
          option.push({
            callback: this.unzip,
            title: "zip解压缩",
          });
          break;
        case "img":
          option.push({
            callback: this.see,
            title: "查看放大",
          });
        case "other":
      }
      option.push({
            callback: this.reName,
            title: "重命名",
          });
          option.push({
            callback: this.delFile,
            title: "删除",
            icon: "el-icon-delete",
          });
      return option;
    },
    see(){
      this.dialogVisible=true;
    },
    rightClick(event) {
      this.$MenuRight.show({
        options: this.getOption(),
        style: {
          left: event.x + "px",
          top: event.y + "px",
        },
      });
    },
    reName() {
      this.style={
        color:'red',
        contenteditable:"true"
      }
      this.contentEditable=true;
      this.$nextTick(()=>{
      let filename =this.$refs['fileName'];
      filename.focus();
      let sec =window.getSelection();
      sec.selectAllChildren(filename)
      })
    },
    blur(e){
      this.$emit("reName", {path:this.path,value:e.target.innerText});
    },
    zip(){
      this.$emit("zip",this.$props);
    },
    unzip(){
      this.$emit("unzip",this.$props);
    },
    delFile() {
      this.$emit("delFile", this.$props);
    },
    showImg() {
      this.$emit("showImg", this.path);
    },
    openDir() {
      this.$emit("openDir", this.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.boxFile {
  width: 100px;
  // height:100px;
  margin: 20px;
  float: left;
  cursor: pointer;
  position: relative;
  .con{
    height: 100px;
  }
  .show {
    display: none;
    border: 1px solid #999;
    position: fixed;
    background-color: #00000050;
    border-radius: 5px;
    .showCon {
      width: 100%;
    }
    .text {
      color: #ffffff;
      text-align: center;
      padding: 3px;
    }
    .text:hover {
      background-color: #ffffff90;
      color: #000099;
    }
    .del:hover {
      color: #ffffff;
      background-color: #ee000080;
    }
  }
}
// .boxFile:hover .show{
//         display: flex!important;
//     }
img {
  max-width: 100%;
  max-height: 100%;
}
.img {
  height: auto;
}
</style>
