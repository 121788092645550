<template>
  <div class="te-ar-lisy">
    <el-container class="list">
      <el-aside width="180px"
                v-if="!orgId">
        <ul class="menu">
          <li :index="item.categoryValue"
              :key="item.categoryValue"
              v-for="item in typeList"
              :class="{'is-active':item.categoryValue===activeName}"
              @click="handleSelect(item.categoryValue)">
            <i class="el-icon-s-order"></i>
            {{item.categoryKey}}
          </li>
        </ul>
      </el-aside>
      <el-container>
        <el-header class="content__header"
                   :style="isOption?'height:auto !important ':''">
          <div class="content__box content__nav">
            <div class="content__add"
                 v-if="!isOption"
                 @click="handleAdd">
              <img :src="`${$router.options.base}img/new-project.png`"
                   height="40px"
                   alt="">
              <p>新建大屏</p>
            </div>
            <div class="content__zy"
                 v-if="page.total>0">
              <el-select v-model="value"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.id"
                           :label="item.categoryKey"
                           :value="item.id">
                </el-option>
              </el-select>
              <el-button type="primary"
                         @click="transferClick()"
                         :loading="transferLoding">转移</el-button>
            </div>
            <div class="content__page">
              <el-pagination v-if="page.total>0"
                             layout="total, sizes, prev, pager, next, jumper"
                             background
                             size="mini"
                             @size-change="handleSizeChange"
                             @current-change="handleCurrentChange"
                             :page-size="page.size"
                             :current-page.sync="page.page"
                             :total="page.total">
              </el-pagination>
            </div>

          </div>
        </el-header>
        <el-main class="content">
          <div class="content__box"
               v-if="list.length!==0">
            <div class="content__item"
                 v-for="(item,index) in list"
                 :key="index"
                 @mouseover="item._menu=true"
                 @mouseout="item._menu=false">
              <div class="content__info"
                   :style="isOption?'height:100% !important ':''">
                <img v-if="item.backgroundUrl"
                     @click="isOption?addActive(item):''"
                     :src="BASEPATHIMG+item.backgroundUrl"
                     alt="" />
                <div v-if="!isOption"
                     class="content__menu"
                     v-show="item._menu">
                  <div class="content__btn"
                       @click="handleEdit(item)">
                    编辑
                  </div>
                  <div class="content__btn"
                       @click="handleExport(item)">
                    导出
                  </div>
                </div>
              </div>
              <div class="content__main">
                <span class="content__name">{{item.title}}</span>
                <div class="content__menulist">
                  <div class="content__view">
                    <el-tooltip content="删除">
                      <i class="el-icon-delete"
                         @click="handleDel(item,index)"></i>
                    </el-tooltip>
                    <el-tooltip content="编辑">
                      <i class="el-icon-edit"
                         @click="handleUpdate(item,index)"></i>
                    </el-tooltip>
                    <el-tooltip content="预览">
                      <i class="el-icon-view"
                         @click="handleViews(item,index)"></i>
                    </el-tooltip>
                    <el-tooltip content="复制">
                      <i class="el-icon-copy-document"
                         @click="handleCopy(item,index)"></i>
                    </el-tooltip>
                  </div>
                  <span class="content__status"
                        :class="{'content__status--active':item.status}">
                    {{item.status?'已发布':'未发布'}}
                  </span>
                </div>

              </div>
            </div>
          </div>
          <div v-else
               style="width:100%">
            <el-empty description="没有发现数据"
                      image-size="80"></el-empty>
          </div>
        </el-main>
      </el-container>

      <el-dialog title="编辑大屏"
                 width="50%"
                 :close-on-click-modal="false"
                 :visible.sync="box">
        <avue-form :option="option"
                   v-model="form"
                   @submit="handleSave"></avue-form>
      </el-dialog>
    </el-container>
    <template v-if="!list.length">
      <div style="height:92px;line-height:92px;color:#fff;text-align: center;">暂无月度产值预警</div>
    </template>
  </div>
</template>
<script>
import { getList, updateObj, delObj, getCategory, copyObj, transferApi } from '@/api/visual';
import { getObj } from '@/api/visual'
import { viewHtml } from '@/utils/html'
import { url } from '@/config'
console.log()
export default {
  name: "list",
  props: {
    isOption: {
      type: Boolean,
      default: false,
    },
    activeID: {
      type: [Object, Array],
      default: function () {
        return []
      },
    },
    orgId: {
      type: [String, Number],
      default: '',
    }
  },
  watch: {
    orgId: {
      handler (val) {
        console.log(val, '1111')
        if (val) {
          this.activeName = val;
          this.getList()
        }
        return val
      },
    },
  },
  data () {
    return {
      typeList: [],
      BASEPATHIMG: process.env.VUE_APP_BASE_PATHIMG + '/',
      box: false,
      option: {

        submitText: '修改大屏',
        column: [{
          label: '分组',
          prop: 'category',
          span: 24,
          labelWidth: 100,
          type: 'select',
          dicUrl: url + '/category/list',
          props: {
            label: 'categoryKey',
            value: 'categoryValue',
          },
          rules: [{
            required: true,
            message: "请选择分组",
            trigger: "blur"
          }]
        }, {
          label: '大屏名称',
          span: 24,
          labelWidth: 100,
          prop: 'title',
          rules: [{
            required: true,
            message: "请输入大屏名称",
            trigger: "blur"
          }]
        }, {
          label: '密码',
          span: 24,
          type: 'password',
          labelWidth: 100,
          prop: 'password',
        }, {
          label: '发布状态',
          prop: 'status',
          span: 24,
          labelWidth: 100,
          type: 'select',
          dicData: [{
            label: '未发布',
            value: 0
          }, {
            label: '已发布',
            value: 1
          }]
        }]
      },
      page: {
        page: 1,
        size: 50,
        total: 0,
      },
      form: {},
      activeName: '',
      list: [],
      options: [],
      value: '',
      transferLoding: false,//转移按钮加载状态
    }
  },
  created () {
    if (!this.orgId) {
      this.getCategory()
    } else {
      this.activeName = this.orgId;
      this.getList()
    }
  },
  methods: {
    handleSelect (key) {
      this.activeName = key;
      this.page.page = 1;
      this.getList();
    },
    vaildData (id) {
      const list = [];
      for (var i = 0; i < 20; i++) {
        list.push(i)
      }
      return list.includes(id)
    },
    getCategory () {
      let datas = {
        page: 1,
        size: 100
      }
      getCategory(datas).then(res => {
        const data = res.data.records.map(item => {
          return {
            ...item,
            categoryKey: item.name,
            categoryValue: item.id,
            id: item.id,
          }
        });
        this.typeList = data;
        console.log(this.typeList, 'this.typeList')
        this.activeName = (data[0] || {}).categoryValue;
        this.getList();
      })
    },
    handleExport (item) {
      getObj(item.id).then(res => {
        const data = res.data;
        let mode = {
          detail: JSON.parse(data.config.detail),
          component: JSON.parse(data.config.component)
        }
        var zip = new window.JSZip();
        zip.file("view.js", `const option =${JSON.stringify(mode, null, 4)}`);
        zip.file('index.html', viewHtml);
        zip.generateAsync({ type: "base64" })
          .then(function (content) {
            location.href = "data:application/zip;base64," + content;
          });
      })
    },

    handleCopy (item) {
      if (this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return
      }
      this.$confirm('确认复制当前大屏', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        copyObj(item.id).then((res) => {
          this.$message.success('复制成功');
          const id = res.data;
          this.handleEdit({ id })
        })
      }).catch(() => {

      });
    },
    handleDel (item, index) {
      if (this.vaildData(index) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.$confirm('是否确认永久删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delObj(item.id).then(() => {
          this.list.splice(index, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {

      });
    },
    handleAdd () {
      this.$router.push({
        path: '/create', query: {
          category: this.activeName
        }
      })
    },
    addActive (item) {
      this.activeID = [item.id]
      this.addactiveID()
    },
    addactiveID () {
      this.$emit("change", this.activeID);
    },
    handleUpdate (item, index) {
      if (this.vaildData(Number(index)) && this.$website.isDemo) {
        this.$message.error(this.$website.isDemoTip)
        return false;
      }
      this.form = item
      this.form.category = this.form.category + '';
      this.box = true;
    },
    handleEdit (item) {
      let routeUrl = this.$router.resolve({
        path: '/build/' + item.id
      })
      window.open(routeUrl.href, '_blank');
    },
    handleViews (item) {
      let routeUrl = this.$router.resolve({
        path: 'view/' + item.id
      })
      window.open(routeUrl.href, '_blank');
    },
    handleSave (form, done) {
      updateObj({
        id: this.form.id,
        category: this.form.category,
        password: this.form.password,
        status: this.form.status,
        title: this.form.title
      }).then(() => {
        done();
        this.$message.success('修改成功');
        this.getList();
      })
    },
    handleCurrentChange (val) {
      this.page.page = val;
      this.getList();
    },
    handleSizeChange (val) {
      this.page.size = val;
      this.getList();
    },
    getList (category) {
      console.log(category)
      this.list = []
      getList({
        category: category || this.activeName,
        page: this.page.page,
        size: this.page.size,
      }).then(res => {
        const data = res.data;
        this.page.total = data.total;
        this.list = data.records
        this.initData();
      })
    },
    initData () {
      this.list.forEach((ele, index) => {
        this.$set(this.list[index], '_menu', false)
      })
    },
    transferClick () {//列表转移函数
      console.log(this.value, '要转移的对象')
      console.log(this.activeName, '当前对象的id')
      if (!this.value) {
        this.$message.warning('请先选择');
        return
      }
      if (this.value == this.activeName) {
        this.$message.warning('目标不能和当前一样');
        return
      }
      this.transferLoding = true
      let data = {
        value: this.value,
        activeName: this.activeName
      }
      transferApi(data).then(res => {
        this.transferLoding = false
        console.log(res)
      })
    },
  }
}
</script>
<style lang="scss">
@import "@/styles/list.scss";
.addAc_crrr {
  font-size: 14px;
  padding-top: 5px;
  color: #fff;
  padding-top: 5px;
  align-content: center;
  display: flex;
}
.addAc_crrr .adId {
  font-size: 16px;
  min-width: 55px;
}
.listFlex {
  display: flex;
  flex-wrap: wrap;
}
.addAc_crrr .list {
  font-size: 14px;
  padding: 0px 7px;
  display: inline;
}
.content__zy {
  display: flex;
  align-items: center;
}
</style>
