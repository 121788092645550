<template>
  <div class="controller">
    <div id="views"
         v-if="active">
      <div class="content">
        <div id="img">
          <div v-if="box"
               id="player-wrapper"></div>
          <iframe id="iframeId01"
                  v-else-if="item.frameId"
                  frameborder="no"
                  border="0"
                  :src="getIframe()"></iframe>
          <img v-else-if="count != null"
               :src="getSrc(item)"
               v-for="item in 120"
               :key="item"
               @click="box1 = true" />
        </div>
        <div id="text">
          <h2>{{ item.name }}</h2>
          <h3>{{ item.note }}</h3>
        </div>
      </div>
    </div>
    <ul class="nav"
        v-if="!active">
      <li v-for="(item, index) in nav"
          :key="index"
          @click="setNav(item)">
        {{ item.name }}
      </li>
    </ul>
    <template v-else>
      <ul class="menu">
        <li class="menu_main"
            @click="handleBack">返回主菜单</li>
        <li class="menu_main"
            @click="handleBreak">上一步</li>
        <li class="menu_main"
            @click="handleNext">下一步</li>
        <li class="menu_main"
            @click="getMoni">模拟器</li>
        <li class="menu_main"
            @click="goIframe"
            v-if="item.frameId">
          放大画面
        </li>
        <li class="menu_main"
            @click="goIframe(true)"
            v-if="item.frameId">
          设计画面
        </li>
      </ul>
    </template>
    <div id="content">
      <ul id="result"
          v-loading="loading"
          element-loading-text="指令发送中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)">
        <li v-for="(item, index) in list"
            :class="{ 'is-active': count == index }"
            :key="index"
            @click="showImg(item, index)"
            @dblclick="sendMsg">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <el-dialog :visible.sync="box1"
               width="80%">
      <div id="dialog">
        <img :src="getSrc(item)"
             v-for="item in 120"
             :key="item" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import clappr from "clappr";
import { url } from '@/config'
export default {
  data () {
    return {
      loading: false,
      box: false,
      box1: false,
      item: {},
      active: null,
      count: null,
      list: [],
      nav: [],
    };
  },
  created () {
    this.getInit();
  },
  methods: {
    goIframe (type) {
      let frameId = this.item.frameId;
      if (type == true) {
        this.$router.push({ path: "/build/" + frameId })
      } else {
        this.$router.push({ path: "/view/" + frameId })
      }
    },
    handleBack () {
      this.active = null;
      this.box = false;
      this.list = [];
      this.item = {};
      this.count = null;
    },
    handleBreak () {
      this.box = false;
      if (this.count < 0) return;
      if (this.count == null) {
        this.count = 0;
      } else {
        this.count = this.count - 1;
      }
      this.item = this.list[this.count];
      this.sendMsg();
    },
    getMoni () {
      window.open('/map/index.html?group=' + this.active.code);
    },
    handleNext () {
      this.box = false;
      if (this.count === this.list.length - 1) return;
      if (this.count == null) {
        this.count = 0;
      } else {
        this.count = this.count + 1;
      }
      this.item = this.list[this.count];
      this.sendMsg();
    },
    getSrc (item) {
      let count = 0;
      if (item < 10) {
        count = "00" + item;
      } else if (item < 100) {
        count = "0" + item;
      } else {
        count = item;
      }
      return (
        url +
        "/getFile/ChatServeImg/" +
        this.item.condition +
        "/" +
        count +
        ".png"
      );
    },
    getIframe () {
      let result = "";
      if (this.item.frameId) {
        result = "/map/view.html?id=" + this.item.frameId +'&closeAudio=true';//加上closeAudio标识，在控制器列表预览时不播报语音
      }
      if (document.getElementById('iframeId01')) {
        setTimeout(() => {
          document.getElementById('iframeId01').contentWindow.location.reload(true);
        }, 500)
      }
      return result;
    },
    showImg (item, index) {
      this.item = item;
      this.count = index;
      this.box = false;
    },
    sendMsg () {
      this.loading = true
      axios
        .post(url + `/zhdt/recvQuestion?appId=${this.active.appId}&groupCode=${this.active.code}`, {
          content: this.item.condition,
          fromUser: "openid",
          msgType: "text",
        })
        .then(() => {
          this.loading = false
          this.$message.success("指令发送成功");
        });
    },
    setNav (item) {
      this.active = item;
      this.getList();
    },
    getInit () {
      this.item = {};
      axios
        .get(url + "/org-manage/list?size=100")
        .then((res) => {
          const data = res.data.data.records;
          this.nav = data;
        });
    },
    getList () {
      this.list = [];
      this.count = null;
      axios
        .get(
          "api/zhdt/conditions?grouCode=" +
          this.active.code
        )
        .then((res) => {
          const data = res.data.data;
          this.list = data.sort(
            (a, b) => a.condition.substr(0, 4) - b.condition.substr(0, 4)
          );
        });
    },
  },
};
</script>

<style scoped lang="scss">
.controller {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: #142a40;
  width: 100%;
  height: 100%;
  padding: 10px;
  ul {
    margin: 0;
    padding: 0;
  }
  ul li {
    list-style: none;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
  }
  .nav li {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 110px;
    height: 40px;
    line-height: 40px;
    background-color: #409eff;
    text-align: center;

    color: #fff;
    font-size: 14px;
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
  }
  .menu li {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 110px;
    background-color: #f56c6c;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
  .menu_main {
    background-color: #409eff !important;
  }
  .menu_phone {
    background-color: #e6a23c !important;
  }
  .menu_bg {
    background-color: transparent !important;
  }

  #result {
    display: flex;
    flex-wrap: wrap;
  }
  #result .is-active {
    color: #fff;
    font-weight: 900;
  }
  #result li {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 110px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #666;
    background-color: #67c23a;
  }
  #views {
    margin-bottom: 20px;
  }
  #views .content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background: #142a40;
  }
  #text {
    flex: 1;
    margin-left: 20px;
  }
  #img {
    margin: 0 auto;
    box-sizing: border-box;
    width: 335px;
    height: 190px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 1px solid #fff;
  }

  #img img {
    width: 10%;
  }
  #dialog {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 5px 10px;
    width: 1000px;
    height: 580px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border: 1px solid #fff;
  }
  #dialog img {
    width: 10%;
  }
  #content {
    flex: 1;
    overflow: scroll;
  }
  #player-wrapper {
    width: 100%;
    height: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  @media only screen and (max-width: 481px) {
    #text {
      display: none;
    }
  }
}
</style>
