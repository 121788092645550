import { render, staticRenderFns } from "./controller2.0.vue?vue&type=template&id=357509f1&scoped=true&"
import script from "./controller2.0.vue?vue&type=script&lang=js&"
export * from "./controller2.0.vue?vue&type=script&lang=js&"
import style0 from "./controller2.0.vue?vue&type=style&index=0&id=357509f1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "357509f1",
  null
  
)

export default component.exports